import React from 'react';
import {createRoot} from 'react-dom/client';
import BasicOverlay from "./BasicOverlay";
import ConfirmDialog from "./Dialogs/ConfirmDialog";
import ErrorDialog from "./Dialogs/ErrorDialog";
import AlertDialog from "./Dialogs/AlertDialog";
import Dialog from "./Dialogs/Dialog";

const tf = {
	/**
	 * @see https://stackoverflow.com/questions/6659351/removing-all-script-tags-from-html-with-js-regular-expression?answertab=active
	 * @param stringOfHtml
	 * @returns {*} string
	 */
	stripScripts: (stringOfHtml) => {
		let div = document.createElement('div');
		div.innerHTML = stringOfHtml;
		let scripts = div.getElementsByTagName('script');
		let i = scripts.length;
		while(i--){
			scripts[i].parentNode.removeChild(scripts[i]);
		}
		return div.innerHTML;
	},
	/**
	 * @link http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	 * @returns string
	 */
	uuid: () => {
		const lut = [];
		for(let i = 0; i < 256; i++){
			lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
		}

		function e7() {
			const d0 = Math.random() * 0xffffffff | 0;
			const d1 = Math.random() * 0xffffffff | 0;
			const d2 = Math.random() * 0xffffffff | 0;
			const d3 = Math.random() * 0xffffffff | 0;
			return lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff] + '-' +
				lut[d1 & 0xff] + lut[(d1 >> 8) & 0xff] + '-' + lut[((d1 >> 16) & 0x0f) | 0x40] + lut[(d1 >> 24) & 0xff] + '-' +
				lut[(d2 & 0x3f) | 0x80] + lut[(d2 >> 8) & 0xff] + '-' + lut[(d2 >> 16) & 0xff] + lut[(d2 >> 24) & 0xff] +
				lut[d3 & 0xff] + lut[(d3 >> 8) & 0xff] + lut[(d3 >> 16) & 0xff] + lut[(d3 >> 24) & 0xff];
		}

		return e7();
	},
	/**
	 * Get URL Parameter added decodeURIComponent()
	 * @link http://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-url-parameter
	 * @param name
	 * @param url
	 * @returns {null}|string
	 */
	gup: (name, url) => {
		if(!url) url = window.location.href;
		name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regexS = "[\\?&]" + name + "=([^&#]*)";
		const regex = new RegExp(regexS);
		const results = regex.exec(url);
		return results === null ? null : decodeURIComponent(results[1]);
	},
	/**
	 * Handles standard error formats
	 * @param response object
	 * @param onClose function
	 */
	rpcError: (response, onClose) => {
		let messages = [];
		if(response.error){
			let {root, removeFromDom} = tf.createRootNode({});

			let close = () => {
				// clean the dom up
				removeFromDom();
				if(typeof onClose === 'function'){
					onClose();
				}
			};

			if(response.error.message){
				let message = (<div className="alert alert-info" role="alert">{response.error.message}</div>);
				return root.render(<ErrorDialog onClose={close}>{message}</ErrorDialog>);
			}
			else if(Object.prototype.toString.call(response.error) === '[object Array]'){
				for(let i in response.error){
					if(!response.error.hasOwnProperty(i)){
						continue;
					}
					if(response.error[i].message){
						messages.push(response.error[i].message);
					}
					else{
						messages.push(response.error[i]);
					}
				}
				if(messages.length > 0){
					let message = messages.map((msg, index) => {
						return (<div key={index} className="alert alert-info" role="alert">{msg}</div>)
					});
					return root.render(<ErrorDialog onClose={close}>{message}</ErrorDialog>);
				}
				else{
					// error with no message
				}
			}
			else{
				let reload = response.reload || false;
				let message = <div className="alert alert-info" role="alert">{response.error}</div>;
				return root.render(<ErrorDialog onClose={close} reload={reload}>{message}</ErrorDialog>);
			}
		}
		else{
			// unknown
		}
	},
	/**
	 * Handles standard error formats.
	 * @param content string|object
	 * @param okLabel string|object
	 * @param onCancel function
	 * @param onClose function
	 */
	alert: ({content, onCancel, onClose, okLabel = 'OK'}) => {
		let {root, removeFromDom} = tf.createRootNode({});

		let handleCancel = () => {
			if(typeof onCancel === 'function'){
				onCancel()
			}
		};

		let handleClose = () => {
			// clean the dom up
			removeFromDom();
			if(typeof onClose === 'function'){
				onClose();
			}
		};

		if(typeof content === 'string'){
			content = <div dangerouslySetInnerHTML={{__html: content}}/>;
		}
		return root.render(<AlertDialog onCancel={handleCancel} onClose={handleClose} okLabel={okLabel}>{content}</AlertDialog>);
	},
	/**
	 * Creates a confirm dialog.
	 * @param content string|object
	 * @param onYes function
	 * @param onCancel function
	 * @param onClose function
	 * @param labels object
	 * @param labels.ok string
	 */
	confirm: ({content, onYes, onCancel, onClose, labels = {}}) => {
		let {root, removeFromDom} = tf.createRootNode({});

		let handleCancel = () => {
			if(typeof onCancel === 'function'){
				onCancel()
			}
		};

		let handleClose = () => {
			// clean the dom up
			removeFromDom();
			if(typeof onClose === 'function'){
				onClose()
			}
		};

		if(typeof content === 'string'){
			content = <div dangerouslySetInnerHTML={{__html: content}}/>
		}
		return root.render(<ConfirmDialog onYes={onYes} onCancel={handleCancel} onClose={handleClose} labels={labels}>
			<div className="alert alert-light" role="alert">{content}</div>
		</ConfirmDialog>);
	},
	/**
	 * used for asking simple questions that require entering into an input.
	 * @param content string|object
	 * @param okLabel string|object
	 * @param onOk function
	 * @param onClose function
	 */
	prompt: ({content, onClose, onSubmit, okLabel = 'Submit', placeholder = ''}) => {
		let {root, removeFromDom} = tf.createRootNode({});

		let value = '';

		let ok = (e) => {
			e.preventDefault();
			// clean the dom up
			removeFromDom();
			if(typeof onSubmit === 'function'){
				onSubmit(value);
			}
		};

		let close = () => {
			// clean the dom up
			removeFromDom();
			if(typeof onClose === 'function'){
				onClose();
			}
		};

		if(typeof content === 'string'){
			content = <div dangerouslySetInnerHTML={{__html: content}}/>;
		}
		root.render(<Dialog onClose={close} onOk={ok} okLabel={okLabel}>
			<div className="alert alert-light" role="alert">
				{content}

				<div className="input-group mb-3" style={{marginTop: '1em'}}>
					<input type="text" className="form-control" placeholder={placeholder}
					       aria-label={placeholder}
					       aria-describedby="button-addon2"
					       autoFocus={true}
					       onChange={(e) => {
						       value = e.target.value
					       }}/>
					<div className="input-group-append">
						<button className="btn btn-primary" type="button" id="button-addon2" onClick={ok}>Submit</button>
					</div>
				</div>

			</div>
		</Dialog>);
	},
	/**
	 * Creates a dialog.
	 * @param content string|object
	 * @param title string|object
	 * @param onCancel function fired if the user clicks the X in the top right
	 * @param onClose function fired no matter how the overlay is closed
	 * @param dataForContent object this will be added as the dataForContent prop to the given content if it's a React Component
	 */
	dialog: ({content = null, title = null, onCancel = null, onClose = null, dataForContent = null}) => {
		let {root, removeFromDom} = tf.createRootNode({});

		let handleCancel = () => {
			// this is only fired if the user clicks the X in the top right
			if(typeof onCancel === 'function'){
				onCancel();
			}
		};

		let handleClose = () => {
			// clean the dom up
			// this is fired no matter how the overlay is closed
			removeFromDom();
			if(typeof onClose === 'function'){
				onClose();
			}
		};

		if(typeof content === 'string'){
			content = <div dangerouslySetInnerHTML={{__html: content}}/>;
		}
		else if(React.isValidElement(content)){
			content = React.cloneElement(content, {
				dataForContent,
				closeDialog: handleClose,
				cancelDialog: () => {
					removeFromDom();
					handleCancel();
				}
			});
		}
		else{
			// use params.content as is
		}
		return root.render(<Dialog onCancel={handleCancel} onClose={handleClose} title={title}>{content}</Dialog>);
	},

	/**
	 *
	 * @param appendTo optional dom element to create node inside
	 * @return {{mountNode: HTMLElement, removeFromDom: removeFromDom, uuid: (*|string)}}
	 */
	createRootNode: ({appendTo = document.body}) => {
		let uuid = tf.uuid();
		let mountNode = document.createElement("div");
		mountNode.id = uuid;
		appendTo.appendChild(mountNode);
		let root = createRoot(mountNode)
		return {
			root,
			mountNode,
			uuid,
			removeFromDom: () => {
				// clean the dom up
				setTimeout(() => {
					root?.unmount();
					mountNode?.remove();
				}, 0);
			}
		}
	},

	/**
	 *
	 * @param params object
	 * @param params.url string
	 * @param params.method string
	 * @param params.params object
	 * @param params.suppressErrorAlert boolean
	 * @return {{cancel(): void, promise: Promise<any>}}
	 */
	rpc: (params) => {
		return tf.makeCancelable(new Promise((resolve, reject) => {
			if(!params.method){
				reject(new Error('method required'));
				return;
			}

			const defaults = {
				method: '',
				params: {},
				url: 'rpc/'
			};

			if(process.env){
				if(typeof process.env.REACT_APP_PUBLIC_URL === 'string'){
					defaults.url = process.env.REACT_APP_PUBLIC_URL + '/rpc/';
				}
				else if(process.env.PUBLIC_URL){
					defaults.url = process.env.PUBLIC_URL + '/rpc/';
				}
			}

			let options = Object.assign({}, defaults, params);


			fetch(options.url,
				{
					method: "POST", // *GET, POST, PUT, DELETE, etc.
					mode: "cors", // no-cors, cors, *same-origin
					cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
					credentials: "same-origin", // include, same-origin, *omit
					headers: {
						"Content-Type": "application/json; charset=utf-8",
					},
					body: JSON.stringify({method: options.method, params: options.params}),
				}
			).then((response) => response.json())
				.then((data) => {

					if(typeof data.result !== 'undefined'){
						resolve(data);
						return;
					}
					else if(typeof data.error !== 'undefined'){
						if(!params.suppressErrorAlert){
							tf.rpcError(data);
						}
					}
					else{
						// unknown protocol
					}

					reject(data);
				}).catch((e) => {
				// connection failure
				reject(e);
				if(!params.suppressErrorAlert){
					if(!tf.connFailure){
						tf.connFailure = tf.rpcError({
							error: 'Connection Failure', onClose: () => {
								tf.connFailure = null
							}
						});
					}
				}
			});
		}));
	},


	/**
	 *
	 * @param method string
	 * @param url  string
	 * @param params object
	 * @param suppressErrorAlert boolean
	 * @return {{cancel(): void, promise: Promise<any>}}
	 */
	api: (method, url, params = {}, suppressErrorAlert = false) => {
		return tf.makeCancelable(
			new Promise((resolve, reject) => {
				if(!method){
					reject(new Error('Http request method required'));
					return;
				}

				if(!url){
					reject(new Error('url required'));
					return;
				}

				const defaults = {
					params: params,
					url: url
				};

				if(url.startsWith("https://") || url.startsWith("http://")){

				}
				else{
					if(process.env){
						if(typeof process.env.REACT_APP_PUBLIC_API_ENDPOINT === 'string'){
							defaults.url = process.env.REACT_APP_PUBLIC_API_ENDPOINT + url;
						}
						else if(process.env.PUBLIC_API_ENDPOINT){
							defaults.url = process.env.PUBLIC_API_ENDPOINT + url;
						}
					}
				}

				let options = Object.assign({}, defaults);
				let init = {}, remoteUrl = options.url;
				if(method === "GET"){
					init = {
						method: "GET", // *GET, POST, PUT, DELETE, etc.
						mode: "cors", // no-cors, cors, *same-origin
						cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
						credentials: "same-origin", // include, same-origin, *omit
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						},
					};
					remoteUrl = remoteUrl + '?' + (new URLSearchParams(options.params)).toString()
				}
				else if(method === "POST"){
					init = {
						method: "POST", // *GET, POST, PUT, DELETE, etc.
						mode: "cors", // no-cors, cors, *same-origin
						cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
						credentials: "same-origin", // include, same-origin, *omit
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						},
						body: JSON.stringify(options.params),
					};
				}

				fetch(remoteUrl, init)
					.then((response) => {
						if(!response.ok){
							throw response;
						}
						return response.json();
					})
					.then((data) => {
						if(typeof data.result !== 'undefined'){
							resolve(data);
							return;
						}
						else if(typeof data.error !== 'undefined'){
							if(!suppressErrorAlert){
								tf.rpcError(data);
							}
						}
						else{
							// unknown protocol
						}
						reject(data);
					}).catch(e => {
					if(e.text){
						e.text().then(errorMessage => {
							if(typeof errorMessage === 'string'){
								let error = JSON.parse(errorMessage);
								if(!suppressErrorAlert){
									tf.rpcError(error);
								}
								reject(error);
							}
							else{
								reject(errorMessage);
							}
						});
					}
					else{
						console.log(e);
					}
				});
			})
		);
	},


	/**
	 * Takes a postal code and optionally a country ISO 2 character code and returns the location information municipality, region, country.
	 * @param address Object
	 * @param address.postalCode string
	 * @param address.country string
	 * @param callback function
	 */
	lookupAddress: (address, callback) => {
		const url = new URL('https://maps.googleapis.com/maps/api/geocode/json');

		let params = {components: 'postal_code:' + address.postalCode, key: process.env.REACT_APP_GOOGLE_API_KEY};
		if(address.country){
			params.components += '|country:' + address.country
		}
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

		fetch(url,
			{
				method: "GET", // *GET, POST, PUT, DELETE, etc.
				mode: "cors", // no-cors, cors, *same-origin
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				credentials: "same-origin", // include, same-origin, *omit
			}
		).then((response) => response.json())
			.then((d) => {
				let response = {};
				if(d.results && d.status === 'OK'){

					d.results[0].address_components.forEach(function(item) {
						if(item.types){
							for(let i = 0; i < item.types.length; i++){
								if(item.types[i] === 'country'){
									response.country = item.short_name;
								}
								if(item.types[i] === 'administrative_area_level_1'){
									response.region = item.short_name;
								}
								if(item.types[i] === 'locality'){
									response.municipality = item.long_name;
								}
							}
						}
					});
					callback(response);
					return;
				}
				callback({});
			}).catch((e) => {
			console.log(e);
			callback({});
		});
	},

	/**
	 * wraps a promise to enable it to be canceled
	 * @link https://github.com/facebook/react/issues/5465#issuecomment-157888325
	 * @param promise
	 * @return {{cancel(): void, promise: Promise<any>}}
	 */
	makeCancelable: (promise) => {
		let hasCanceled_ = false;

		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then(
				val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
				error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
			);
		});

		return {
			promise: wrappedPromise,
			cancel() {
				hasCanceled_ = true;
			},
		};
	}
};


export default tf;
export {ErrorDialog, AlertDialog, ConfirmDialog, BasicOverlay, Dialog};
