import {useNavigate, useParams, useLocation} from "react-router-dom";

/**
 * Inject params, navigate and location props
 * @param Component
 * @returns {function(*): *}
 */
export default function withParams(Component) {
	return props => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()}/>;
}
