import React from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import LoginForm from "./components/index";
import ForgetPasswordForm from "./components/ForgotPasswordForm";


class App extends React.Component {


	render() {
		return (

			<BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
				<div className="tcp-login">
					<Routes>

					<Route
						path="/enroller.php"
						exact
						element={<LoginForm/>}
					/>

					<Route
						path="/enroller"
						exact
						element={<LoginForm/>}
					/>

					<Route
						path="/forgot-password"
						exact
						element={<ForgetPasswordForm/>}
					/>
					<Route
						path="/login.php"
						exact
						element={<LoginForm/>}
					/>

					<Route
						path="/login"
						exact
						element={<LoginForm/>}
					/>
					</Routes>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;

